<template>
  <div class="detail_panel">
    <div class="table_header" :title="detailInfo.name">
      {{detailInfo.name}}
    </div>
    <div class="table_content">
      <div class="table_line" v-for="(row,rIdx) in detailInfo.content" :key="rIdx">
        <div class="row_label">
          {{row.label}}
        </div>
        <div class="row_value">
          <template v-if="row.isPic">
            <img :src="row.value" alt="">
          </template>
          <template v-else>
            {{row.value}}
          </template>
        </div>
      </div>
    </div>
    <div class="back_btn">
      <div class="btn_el" @click="$emit('goBack')">返回列表</div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  name:"compDetail",
  props:{
    detailInfo:{
      type:Object,
      default:()=>{
        return {
          name:"未知",
          details:[]
        }
      }
    }
  },
  components: {
  },
  // 定义属性
  data() {
    return {
      
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取图片
    getPic(name){
      return require(`../../images/${name}.png`)
    }
    
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
  .detail_panel{
    width: 100%;
    .table_header{
      width: 100%;
      height: 93px;
      background: #0086D1;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 36px;
      color: #FFFFFF;
      line-height: 93px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      padding: 0 10px;
      box-sizing: border-box;
    }
    .table_content{
      .table_line{
        width: 100%;
        display: flex;
        .row_label{
          flex: 328;
          overflow: hidden;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 18px;
          color: #0086D1;
          line-height: 24px;
          border-left: 1px solid #87939B;
          border-right: 1px solid #87939B;
          border-bottom: 1px solid #87939B;
          padding: 12px 50px;
          box-sizing: border-box;
        }
        .row_value{
          flex: 1094;
          overflow: hidden;
          border-right: 1px solid #87939B;
          border-bottom: 1px solid #87939B;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #444444;
          line-height: 24px;
          padding: 12px 50px;
          box-sizing: border-box;
          img{
            max-height: 325px;
            max-width: 100%;
          }
        }
      }
    }
    .back_btn{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #0086D1;
      line-height: 48px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      .btn_el{
        cursor: pointer;
      }
    }
  }
</style>